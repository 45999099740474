<template>
	<el-container style="height: 100%">
		
		<!-- 左侧 -->
		<el-aside :width="fold?'auto':'170px'">
			
			<!-- 深蓝色背景  #00161d  color #fff -->
			<el-menu 
				background-color="#0c1d40" 
				text-color="#fff" 
				active-text-color="#ffd04b" 
				class="main_menu" 
				@select="select" 
				:collapse="fold" 
				:unique-opened="true" 
				:collapse-transition="false" 
				:default-active="currentMenu"
			>
				
				<!-- 左侧logo -->
				<div class="logo"><img src="../../res/img/logo.png"></div>

				<!-- 左侧菜单 -->
				<el-submenu v-for="(group,idx) in menu" :key="idx" :index="idx.toString()">
					
					<!-- 菜单组 -->
					<template slot="title">
						<i class="icon" :class="group.icon?group.icon:'el-icon-menu'"></i>
						&nbsp;&nbsp;
						<span slot="title">{{ $dict(group.name) }}</span>
					</template>

					<!-- 单个菜单 -->
					<el-menu-item v-for="(item,index) in group.children" :key="index" :index="idx+'-'+index">{{item.name}}</el-menu-item>
				</el-submenu>
			</el-menu>
		</el-aside>

		<!-- 右边 -->
		<el-container class="right_container">

			<!-- 头部一行 -->
			<el-header class="right_header" style="height:50px">
				<div class="left" @click="menu_toggle">
					<i :class="'text-xxl '+(fold?'el-icon-s-unfold':'el-icon-s-fold')"></i>
				</div>
				<div class="middle">
					<div v-for="(item,index) in used_menu" :key="index" :class="{item_current:item==currentMenu}" class="item">
						<div class="text" @click="select(item)">{{ $dict(used_menu_show[item].name) }}</div>
						<div class="del_btn" @click="used_menu_del(index)">×</div>
					</div>
				</div>
				<div class="right">
					<el-select v-model="language_choosed_in_page" style="margin-right: 10px;" @change="languageChange" size="mini">
						<el-option v-for="(item,index) in language_list" :key="index" :label="item.name" :value="item.id"></el-option>
					</el-select>
					<div class="text">
						<span>{{ $dict('车队') }}: {{ truck_carrier_choose.name }}</span>
						&nbsp;
						<span style="cursor: pointer;color: cadetblue;" @click="truck_carrier_choose_view_open(false)">{{ $dict('切换') }}</span>
						&nbsp;&nbsp;&nbsp;
						<span>{{ $dict('成员') }}: {{ app_user_info.name }}</span>
					</div>
					<div class="el-icon-switch-button logout_icon" @click="logout" ></div>
				</div>
			</el-header>

			<!-- 内容区域 -->
			<el-main class="container">
				<router-view></router-view>
			</el-main>

			<!-- 货代选择页面 -->
			<truckCarrierChoose 
				:is_show="truck_carrier_choose.is_show" 
				:auto_choosed="truck_carrier_choose.auto_choosed" 
				@choosed="truck_carrier_choosed" 
			></truckCarrierChoose>
			
		</el-container>
	</el-container>
</template>
<script>
	import truckCarrierChoose from './truck_carrier_choose.vue'
	import {mapMutations, mapState} from 'vuex'
	export default {
		components:{
			truckCarrierChoose
		},
		data() {
			return {

				//菜单是否折叠
				fold: false,

				//选中菜单
				currentMenu: '',
				currentMenuObj: {},

				//菜单数据
				menu: [],

				//访问历史
				used_menu:[],
				used_menu_show:{},

				//语言
				language_choosed_in_page:'',

				//身份选择
				truck_carrier_choose:{
					name:this.$dict('未选择'),
					auto_choosed:false,
					is_show:0,
				},
			}
		},
		computed:{
			...mapState(['app_user_info','truck_carrier_info','now_url','def_page','language_list','language_choosed'])
		},
		watch:{
			now_url(new_data,old_data){
				this.openDefault(new_data)
			}
		},
		created() {

			this.language_choosed_in_page=this.language_choosed

			setTimeout(()=>{
				
				//选择堆场
				this.truck_carrier_choose_view_open(true);
			})
		},
		methods: {

			...mapMutations(['end_of_logout','end_of_login','set_truck_carrier_info','languageChange']),

			//身份选择
			truck_carrier_choose_view_open(auto_choosed){//打开
				this.truck_carrier_choose.auto_choosed=auto_choosed
				this.truck_carrier_choose.is_show++
			},
			truck_carrier_choosed(truck_carrier_member_item){//选定
			
				//关闭界面
				this.truck_carrier_choose.is_show=0

				//置入车队名称
				this.truck_carrier_choose.name=truck_carrier_member_item.truck_carrier_info.name
				
				//缓存堆场信息
				this.set_truck_carrier_info(truck_carrier_member_item.truck_carrier_info);
				
				//如果没有设为默认堆场,则发起询问
				if(truck_carrier_member_item.def_truck_carrier_of_menber==2){
					this.$my.other.confirm({
						content:'点击确定把此项设为默认堆场',
						confirm:()=>{

							//登录
							this.truck_carrier_member_login(true)
						},
						cancel:()=>{
							
							//登录
							this.truck_carrier_member_login(false)
						}
					});
				}else{
					
					//登录
					this.truck_carrier_member_login(false)
				}
			},
			truck_carrier_member_login(set_def){

				//堆场登录
				this.$my.net.req({
					data:{
						mod: 'truck_carrier',
						ctr: 'truck_carrier_login_by_member',
						truck_carrier_id: this.truck_carrier_info.truck_carrier_id,
						set_def: set_def,
					},
					callback:(data)=>{
						this.menu=this.app_user_info.menu_list
						this.menu_init()
						this.fold_init()
						this.def_page_open()
					}
				});
			},

			//打开默认页面
			def_page_open(){
				if(['/','/pages'].indexOf(this.$route.fullPath)==-1)return;
				let menu = this.menu
				for (var i in menu) {
					for (var j in menu[i].children) {
						var path = '/pages/'+menu[i].children[j].url
						if (path == this.def_page) {
							this.select(`${i}-${j}`);
							return;
						}
					}
				}
			},

			//菜单展开折叠
			menu_toggle() {
				this.fold = this.fold ? false : true

				if(this.fold){
					localStorage.setItem('_pages_index_fold',1);
				}else{
					localStorage.setItem('_pages_index_fold',2);
				}
			},

			//菜单初始化
			fold_init(){
				let _pages_index_fold=localStorage.getItem('_pages_index_fold');

				if(_pages_index_fold==1){
					this.fold=true;
				}else if(_pages_index_fold==2){
					 this.fold=false;
				}
			},

			//菜单跳转
			select(index) {
				var idx = index.split("-")
				var menu = this.menu[idx[0]].children[idx[1]]
				var path = "/pages/"+menu.url.replace("#", "")
				if (path != this.$route.fullPath) {

					//跳转
					this.$router.push(path)

					//选中菜单
					this.currentMenu = index
					this.currentMenuObj=menu

					//置入访问历史
					this.set_used_menu(index,menu);
				}
			},

			//置入访问历史
			set_used_menu(ids,menu){

				//如果历史菜单超过12则删除第一个
				if(this.used_menu.length>12){
					this.used_menu.splice(0,1)
				}

				//如果当前菜单已存在于历史菜单则不重复操作
				if(this.used_menu.indexOf(ids)!=-1)return

				//把当前菜单置入历史菜单
				this.used_menu.push(ids)

				//如果历史菜单显示组没有此菜单则置入
				if(!this.used_menu_show[ids]){
					this.used_menu_show[ids]=menu
				}
			},

			//删除访问历史
			used_menu_del(index){
				if(index==0){
					if(this.used_menu.length==1){
						return;
					}
					this.select(this.used_menu[1])
					this.used_menu.splice(0,1)
				}else if(index==this.used_menu.length-1){
					this.select(this.used_menu[index-1])
					this.used_menu.splice(index,1)
				}else{
					this.select(this.used_menu[index+1])
					this.used_menu.splice(index,1)
				}
			},

			//退出登录
			logout(){
				this.$my.other.confirm({
					content:'退出登录,请确认?',
					confirm:()=>{
						this.$my.net.req({
							loading:true,
							data:{
								mod:'app_user',
								ctr:'app_user_logout',
							},
							callback:(data)=>{

								//删除缓存
								this.end_of_logout();

								//跳转回登录页
								this.$router.push('/login');
							}
						});
					}
				});
			},

			//菜单初始化
			menu_init() {

				//取出原始菜单列表
				let menu_list=this.app_user_info.menu_list

				//格式化主菜单
				let menu_main={};
				let menu_children={};

				for(var item of menu_list){

					//页面内菜单
					if(item.fid==0){
						menu_main[item.weight]={
							id:item.id,
							name:item.name,
						}
					}else{
						if(!menu_children[item.fid])menu_children[item.fid]={};
						menu_children[item.fid][item.weight]={
							name:item.name,
							url:item.url,
							keep_alive:item.keep_alive
						}
					}
				}

				//格式化子菜单
				for(var i in menu_main){
					menu_main[i].children=menu_children[menu_main[i].id]
				}

				//置入数据
				this.menu=menu_main

				//打开默认
				this.openDefault()
			},

			//展开并选中当前路由菜单
			openDefault(page_now_url) {
				let menu = this.menu
				let page_path=page_now_url?page_now_url:this.$route.path
				for (var i in menu) {
					for (var j in menu[i].children) {
						var path = '/pages/'+menu[i].children[j].url
						if (path == page_path) {

							//选中菜单
							this.currentMenu = `${i}-${j}`
							this.currentMenuObj=menu[i].children[j]

							//置入访问历史
							this.set_used_menu(this.currentMenu,menu[i].children[j]);
						}
					}
				}
			},
		}
	}
</script>
<style lang="scss" scoped>
	.logo {
		text-align: center;
		padding: 15px 0 0 0;
		img {
			width: 60px;
			padding: 10px 0px;
		}
	}

	.main_menu {
		height: 100%;
		overflow-x: hidden;
	}

	.right_container{
		padding: 0;

		.right_header{
			padding: 0;
			height: 50px;
			line-height: 50px;
			display: flex;
			background-color: rgb(27, 19, 43);
			color: #ccc;
			.left{
				cursor: pointer;
				width: 50px;
				text-align: center;
			}
			.middle{
				flex:1;
				display:flex;
				flex-wrap:wrap;
				overflow:hidden;
				.item{
					position:relative;
					border:1px solid #ccc;
					border-width:1px 1px 0px 1px;
					height:40px;
					line-height:40px;
					margin:9px 10px 0 0;
					font-size:13px;
					padding:0 10px;
					.text{
						width:100%;
						text-align:center;
						cursor:pointer;
					}
					.del_btn{
						position:absolute;
						right:-3px;
						top:-5px;
						font-size:14px;
						background-color:#666;
						color:#eee;
						height:16px;
						line-height:16px;
						width:16px;
						border-radius:8px;
						text-align:center;
						cursor:pointer;
					}
				}
				.item_current{
					background-color:#eaf3fd;
					color:#409eff;
				}
			}
			.right{
				display: flex;
				justify-content: flex-end;
				padding-right: 15px;
				.logout_icon{
					cursor: pointer;
					width: 50px;
					height: 50px;
					line-height: 50px;
					text-align: center;
					font-weight:600;
				}
			}
		}

		.container{
			background-color:#aaa;
			
		}
	}
	
</style>